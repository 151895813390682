<template>
  <panel title="For Homeassistant">
    <form-input
      label="Access Token"
      v-model="form.access_token"
      v-validate="`required`"
      data-vv-as="Access Token"
      :col="12"
    />
    <form-input
      label="URL Repository"
      v-model="form.url_repository"
      v-validate="`required`"
      data-vv-as="URL Repository"
      :col="12"
    />

    <!-- `notify: - name: tollfree_call platform: toll_notify access_token: '${data.access_token}'` -->
  </panel>
</template>
<script>
export default {
  props: {
    form: null,
  },
  // data() {
  //   return {
  //     form: {
  //       access_token: "",
  //       url_repository: "",
  //     },
  //   }
  // },
}
</script>
